import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
const Website = () => {
  return (
    <div>
      
      <a href="https://www.edcitronnelliministries.com/" className="btn btn-outline-primary ms-2" target="_blank">
           휍사이트
        </a>
           
    </div>
  )
}

export default Website
