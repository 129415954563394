import React from 'react'
import { NavLink } from 'react-router-dom'


const Market = () => {
  return (
    <div>
            <a href="https://www.edcitronnelliministries.com/events" className="btn btn-outline-primary ms-2" target="_blank">
        스케줄
        </a>
    </div>
  )
}

export default Market
