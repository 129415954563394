import React from 'react'
import imageCall from './images/무당주술에 대적 불 기도 copy.jpg';
const Blog = () => {
  return (
    <div>
        <section className="" >    
        <div class="container-fluid military" >
          <div class="container" >
          <div class="row">
      {/* <img src={imageCall} alt="ed pastor" className="img-fluid" /> */}
      </div>
      </div></div>
      </section>
      <div>
        <h1>dafdsfasdfdsfd</h1>
      </div>
    </div>
  )
}

export default Blog
