import React from 'react'
import { NavLink } from 'react-router-dom'
const Youtube = () => {
  return (
    <div>
         <a href="https://www.youtube.com/@EdCitronnelliMinistries" className="btn btn-outline-primary ms-2" target="_blank">
         유튜브
        </a>
    </div>
  )
}

export default Youtube
